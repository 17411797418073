import { nextTick } from 'vue'
import { stringifyQueryString } from '@shein/common-function'
import { markPoint } from 'public/src/services/mark/index.js'
import storePageDataManager from 'public/src/pages/store_pages/js/storePageDataManager.js'
import qs from 'query-string'

const { langPath } = gbCommonInfo

function getUrlSearch(urlString) {
  const searchIndex = urlString.indexOf('?')

  if (searchIndex !== -1) {
    const searchParams = urlString.substring(searchIndex)
    return searchParams
  }
  return ''
}
export default {
  methods: {
    createScParams() {
      return []
    },
    boardMoreHandle({ link, type, adp, tabId, goods_id, cat_id, analysis = {}, sort, cur_cat_id = '', isStoreTabSticky = '' }) {
      const { src_module = '', src_identifier = '' } = analysis
      const common_params = {
        sort,
        adp,
        src_module,
        src_identifier,
        src_tab_page_id: getSaPageInfo.tab_page_id || '',
        main_goods_id: goods_id || '',
        main_cate_id: cat_id || '',
        top_cate_id: cur_cat_id,
        isStoreTabSticky,
      }
      if(tabId) {
        common_params.tabId = 'items'
        common_params.isStoreTabSticky = '1'
      }
      const split = !!~link.indexOf('?') ? '&' : '?'
      markPoint('toNextPageClick', 'public')
      if (type === 'store') {
        const store_params = stringifyQueryString({ queryObj: {
          // store_score: shopRank,
          ici: 'PageGoodsDetail',
          rule_poskey: 'DetailShopItemList',
          ...common_params
        } })
        const url = `${langPath}${link}${split}${store_params}`;
        try {
          const queryObj = qs.parse(getUrlSearch(url))
          if (queryObj) {
            storePageDataManager.prerenderRequest({
              fullPath: url,
              query: queryObj,
            })
          }
        } catch(e) {
          console.error(e)
        }


        this.$router.push(url)
      } else {
        const brand_params = stringifyQueryString({ queryObj: common_params })
        this.$router.push(`${langPath}${link}${split}${brand_params}`)
      }
    },
    handleScroll() {
      if(this.flashEntryType !== 'showflashbutton') return
      nextTick(()=>{
        const scrollWidth = this.$refs.flashScrollContent?.clientWidth - this.$refs.flashScrollBox?.clientWidth || 0
        if(scrollWidth > 3) {
          this.hasAnimation = true
          let minSpeed = scrollWidth > 60 ? scrollWidth : 60
          this.millisecond = `${minSpeed * 40}ms`
        }else {
          this.hasAnimation = false
        }
      })
    }
  },
  mounted(){
    this.handleScroll()
  },
  watch: {
    flashEntryType: {
      handler() {
        this.handleScroll()
      },
    }
  },
  data() {
    return {
      flashEntryMap: {
        'none': 0,
        'showbelt': 1,
        'showflashsale': 2,
        'showflashbutton': 3
      },
      hasAnimation: false,
      millisecond: '1000ms',
    }
  }
}
