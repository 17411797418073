<script lang="jsx">
import { defineComponent } from 'vue'
import { expose } from 'public/src/pages/common/analysis/directive'
const { GB_cssRight, SiteUID } = gbCommonInfo

export default defineComponent({
  name: 'ColorV2AddTag',
  directives: {
    expose,
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    colorType: {
      type: String,
      default: 'default',
    },
    preposeColorType: {
      type: String,
      default: 'Thumbnail',
    },
    promotionTagInfos: {
      type: Object,
      default: () => ({})
    },
    labelExpose: {
      type: Function,
      default: () => {}
    },
    publicCdn: {
      type: String,
      default: ''
    },
    isLargeImage: {
      type: Boolean,
      default: false
    },
    isShowAttrNewIcon: {
      type: Boolean,
      default: false
    },
    showColorsTag: {
      type: Boolean,
      default: false
    },
  },
  render({ $props: props }) {
    const { item, promotionTagInfos, labelExpose, isLargeImage, isShowAttrNewIcon, showColorsTag, colorType, preposeColorType } = props
    const { goods_id, hot_color, isEco, isNew, lowPrice, isShowThickening } = item || {}
    const isPreposeBlock = colorType === 'prepose' && preposeColorType === 'colorBlock'
    if (!showColorsTag) return null
    /** 色块tag信息 */
    const renderColorTag = () => {
      if (promotionTagInfos && promotionTagInfos[goods_id] && promotionTagInfos[goods_id].showVisible) {
        if (promotionTagInfos[goods_id].tagType === 1 && promotionTagInfos[goods_id].unit_discount) {
          return (
            <div
              class={!isLargeImage ? 'goods-color__tag-discount' : 'tag-discount'}
              v-expose={labelExpose(goods_id)}
            >
            -{ promotionTagInfos[goods_id].unit_discount }%
            </div>
          )
        } else if (promotionTagInfos[goods_id].tagType === 2) {
          return (
            <div
              class={!isLargeImage ? 'suiiconfont sui_icon_activity_12px goods-color__tag-promotion' : 'suiiconfont sui_icon_activity_12px tag-promotion'}
              v-expose={labelExpose(goods_id)}
            ></div>
          )
        }
        return null
      } else {
        if (isEco === '1') {
          return (
            <img
              class={!isLargeImage ?  `goods-color__tag goods-color__evolu ${isPreposeBlock ? 'goods-color__preColor' : ''}` : 'evolu'}
              src={GB_cssRight ? 'https://img.ltwebstatic.com/images3_ccc/2024/10/10/e8/1728547164df1a42cf52782ac9f22ecdb541a7fa1c.png' : 'https://img.ltwebstatic.com/images3_ccc/2024/09/26/4e/1727353949f470aa5d372776c6db1d0e61e5281d68.png'}
            />
          )
        } else if (isNew === '1' && isShowAttrNewIcon) {
          return (
            <img
              class={!isLargeImage ? `goods-color__tag goods-color__new ${isPreposeBlock ? 'goods-color__preColor' : ''}` : 'new'}
              src={GB_cssRight ? 'https://img.ltwebstatic.com/images3_ccc/2024/10/11/5b/17286260770c1ce60f13a429ee051a0e7f95785650.png' : 'https://img.ltwebstatic.com/images3_ccc/2024/10/11/5b/1728626077f562513c709f3fbd10bf3167bd9dfcef.png'}
            />
          )
        } else if (lowPrice) {
          let src = GB_cssRight ?
            'https://img.ltwebstatic.com/images3_ccc/2024/10/11/4d/1728617483b9d49c429684f137dab29e356e8694c0.png' :
            'https://img.ltwebstatic.com/images3_ccc/2024/10/11/c7/1728617411040bbdfeb78852fd58a094a2180b7a49.png'
          return <img
            class={`goods-color__tag goods-color__low  ${isPreposeBlock ? 'goods-color__preColor' : ''}`}
            src={src}
          />
        } else if (hot_color === '1') {
          return (
            <img
              class={!isLargeImage ? `goods-color__tag goods-color__hot ${isPreposeBlock ? 'goods-color__preColor' : ''}` : 'hot'}
              src={GB_cssRight ? 'https://img.ltwebstatic.com/images3_ccc/2024/10/10/6d/1728547129831115ea4fad4b79f1caec6ca4617c24.png' : 'https://img.ltwebstatic.com/images3_ccc/2024/09/26/36/1727353722d5ac6151867d1504299e8bd37f299251.png'}
            />
          )
        } else if (isShowThickening === '1') {
          const isUs = !!~SiteUID.indexOf('us')
          const iconSrc = isUs ? 'https://img.ltwebstatic.com/images3_ccc/2024/11/19/26/173199720190bdee4d5621bc3a8711dfc8368c03ac.png' :
            GB_cssRight ? 'https://img.ltwebstatic.com/images3_ccc/2024/11/19/66/1731997137d59cf577d54ffb57744fcc0dc744d8a2.png' : 
              'https://img.ltwebstatic.com/images3_ccc/2024/11/19/f8/17319971798747b4825a46092207aa4a1352d09351.png'

          return (
            <img
              class={!isLargeImage ? `goods-color__tag goods-color__thickening ${isPreposeBlock ? 'goods-color__preColor' : ''}` : 'thickening'}
              src={iconSrc}
            />
          )
        }
        return null
      }
    }

    return renderColorTag()
  },
})
</script>
