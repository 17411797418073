<template>
  <div
    :class="{
      'add-cart__animation-normal': !isReview,
      'add-cart__animation-review': isReview,
    }"
  >
    <transition name="slide-bottom">
      <BtnBox 
        v-show="showAddCartBar"
        :isReview="isReview"
        :need-scroll-review-ymal="needScrollReviewYmal"
      />
    </transition>
    
    <ClientOnly>
      <AddPopAnimation
        v-if="allDataReady"
        :isReview="isReview"
      />
    </ClientOnly>
  </div>
</template>

<script setup name="AddToCartBar">
import { defineComponent, onBeforeUnmount, defineAsyncComponent, watch, reactive, nextTick, computed } from 'vue'
import {
  useMapGetters,
  useMapStateV2,
  useMapMutations,
  useMapActions,
} from 'public/src/pages/goods_detail_v2/utils/useVuex.js'
import { ClientOnly } from '@sheinfe/vue-client-only'
import BtnBox from './BtnBox.vue'
const AddPopAnimation = defineAsyncComponent(() => import(/* webpackChunkName: "AddPopAnimationV2" */ 'public/src/pages/goods_detail_v2/innerComponents/drawer/AddPopAnimation.vue'))
import { parseQueryString } from '@shein/common-function'
const props = defineProps({
  isReview: {
    type: Boolean,
    default: false,
  },
  needScrollReviewYmal: {
    type: Boolean,
    default: false,
  },
})
const { 
  addToBagLoading,
  addToBagFail,
  addToBagSuc,
  addToBagFailMsg,
  addLoadingAttachTop,
  promotionLimitType,
} = useMapStateV2('newProductDetail/common', [
  'addToBagLoading',
  'addToBagFail',
  'addToBagSuc',
  'addToBagFailMsg',
  'addLoadingAttachTop',
  'promotionLimitType',
])
const { 
  show_add_cart_bar
} = useMapStateV2('productDetail/addBag', [
  'show_add_cart_bar'
])
const { real_time_api_status } = useMapStateV2('productDetail/common', ['real_time_api_status'])
const {
  allDataReady
} = useMapGetters('newProductDetail/common', [
  'allDataReady'
])
const { 
  language_v2,
} = useMapGetters('productDetail/common', [
  'language_v2',
])
const {
  sku_info,
  mall_code,
} = useMapGetters('productDetail/common', [
  'sku_info',
  'mall_code',
])
const { 
  update_find_similar_float,
} = useMapActions('productDetail/Recommend', [
  'update_find_similar_float',
])
const { 
  updatePromotionLimitType,
} = useMapMutations('newProductDetail/common', [
  'updatePromotionLimitType',
])
const unWatchMap = reactive({
  skuUnWatch: null,
  mallUnWatch: null,
})
const showAddCartBar = computed(() => props.isReview || show_add_cart_bar.value)
watch([addToBagLoading, addToBagFail, addToBagSuc, addToBagFailMsg, promotionLimitType], async () => {
  if (props.isReview) return
  const AddPopInstance = await import('public/src/pages/goods_detail_v2/innerComponents/drawer/AddPop/index.js').then((res) => res.default).catch(() => null)
  let type = ''
  let msgContent = ''
  if (promotionLimitType.value == 2) {
    type = 'limitPop'
  }
  if (addToBagLoading.value) {
    type = 'loading'
  }
  if (addToBagSuc.value) {
    type = 'success'
  }
  if (addToBagFail.value && addToBagFailMsg.value) {
    type = 'fail'
    msgContent = addToBagFailMsg.value
  }
  AddPopInstance?.open?.({
    type,
    msgContent,
    language: language_v2.value,
    isFromBigImage: !!addLoadingAttachTop.value,
    cb: {
      close: () => {
        updatePromotionLimitType?.(null)
      }
    }
  })
})
onBeforeUnmount(() => {
  unWatchMap?.skuUnWatch?.()
  unWatchMap?.mallUnWatch?.()
})
watch(allDataReady, async (value) => {
  if (props.isReview) return
  unWatchMap?.skuUnWatch?.()
  unWatchMap?.mallUnWatch?.()
  if (typeof window !== 'undefined' && value) {
    nextTick(() => {
      const { isFromSwitchColor = '' } = parseQueryString?.(window?.location?.search) || {}
      update_find_similar_float({ from: isFromSwitchColor ? 'skcChange' : 'pageInit' })
      unWatchMap.skuUnWatch = watch(sku_info, (newSkuInfo, oldSkuInfo) => {
        const { sku_code: newSkuCode } = newSkuInfo || {}
        const { sku_code: oldSkuCode } = oldSkuInfo || {}
        newSkuCode !== oldSkuCode && update_find_similar_float({ from: newSkuCode ? 'skuChange' : 'skcChange' })
      })
      unWatchMap.mallUnWatch = watch(mall_code, (newVal, oldVal) => {
        if (newVal !== oldVal) {
          const unWatch = watch(real_time_api_status, (val) => { 
            if (val) {
              unWatch?.()
              update_find_similar_float?.({ from: 'skuChange' })
            }
          })
        }
      })
    })
  }
}, {
  immediate: true,
})
defineComponent({
  name: 'AddToCartBar',
})
</script>

<style scoped lang="less">
.slide-bottom {
  &-enter-active, &-leave-active {
    transition: transform 0.3s ease;
  }

  &-enter-from, &-leave-to {
    transform: translate3d(0, 100%, 0);
  }
}
</style>
