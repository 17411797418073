<template>
  <BHeaderCart
    ref="refBHeaderCart"
    :theme-vars="themeVars"
    :placement="placement"
    :offset-y="offsetY"
    :analysis-source="analysisSource"
    :loc="loc"
    :style="commonHeaderColorVariable"
    :disabledLure="disabledLure"
    @before-click="onBeforeClick"
  />
</template>

<script setup>
import { ref, computed, onMounted, onActivated, nextTick, provide } from 'vue'
import BHeaderCart from '@shein-aidc/bs-header-cart-mobile'

import { web_cart_entry_click } from '@/public/src/pages/cart_v2/utils/metricTagsConfig.js'
import { markPoint } from '@/public/src/services/mark/index.js'

const emit = defineEmits(['before-click'])

const props = defineProps({
  themeVars: {
    type: Object,
    default: () => ({}),
  },
  placement: {
    type: String,
    default: 'bottom-end', // top-start
  },
  offsetY: {
    type: Number,
    default: undefined,
  },
  analysisSource: {
    type: Object,
    default: () => ({}),
  },
  loc: {
    type: String,
    default: 'topsite', // topsite buttonbar
  },
  disabledLure: {
    type: Boolean,
    default: false
  }
})

const commonHeaderColorVariable = computed(() => {
  return {
    '--shein-common-header-icon-color': props.themeVars.iconColor,
    '--shein-common-header-badge-border-color': props.themeVars.iconColor ? '#fff' : ''
  }
})

const onBeforeClick = () => {
  emit('before-click')
  web_cart_entry_click('3')
  markPoint('toNextPageClick', 'public')
}

const expose = () => {
  refBHeaderCart.value?.onExpose()
}

let mounted = false
const refBHeaderCart = ref(null)
onMounted(() => {
  nextTick(() => {
    mounted = true
  })
})
onActivated(() => {
  if (mounted) {
    expose()
  }
})

// 解决 vue 报错
provide('COMMON_HEADER_EVENT_PROXY_PROVIDE', null)
</script>
