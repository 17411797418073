<template>
  <div
    class="product-intro__add-cart fixed self-animated-medium j-btn-add-to-bag-wrapper j-da-event-box NEW_BFF_COMPONENT"
    :class="{ 'no-border-style': has_top_content }"
    code="goodsDetail"
    data-active-from="main"
  >
    <div class="sticky-top-belt-wrap">
      <TipsContent
        :isReview="isReview"
      />
      <LowStockBanner />
    </div>
    <StoreBtn v-if="show_store_btn" />
    <div
      v-if="show_cart_btn_status"
      class="add-cart__cart-btn j-add-cart__cart-btn"
    >
      <CommonCart 
        loc="buttonbar"
        placement="top-start" 
        :offset-y="10"
        :disabledLure="show_float_cart_icon"
        :theme-vars="{ iconSize: 32 }" 
      />
    </div>
    <WishBtn
      v-else
      loc="buttonbar"
      :reviewLocation="isReview ? 'page_review' : ''"
    />
    <MainBtn
      :isReview="isReview"
      :need-scroll-review-ymal="needScrollReviewYmal"
    />
  </div>
</template>

<script setup>
import TipsContent from './TipsContent.vue'
import StoreBtn from './StoreBtn.vue'
import CommonCart from '@/public/src/pages/components/common-cart/index.vue'
import WishBtn from './WishBtn.vue'
import MainBtn from './MainBtn.vue'
import LowStockBanner from './LowStockBanner.vue'
import {
  useMapGetters,
} from 'public/src/pages/goods_detail_v2/utils/useVuex.js'
defineProps({
  isReview: {
    type: Boolean,
    default: false,
  },
  needScrollReviewYmal: {
    type: Boolean,
    default: false,
  },
})
const {
  has_top_content,
  show_store_btn,
  show_cart_btn_status,
} = useMapGetters('productDetail/addBag', [
  'has_top_content',
  'show_store_btn',
  'show_cart_btn_status',
])

const {
  show_float_cart_icon
} = useMapGetters('productDetail/common', [
  'show_float_cart_icon',
])

</script>

<style lang="less">
// * 基类名 product-intro__add-cart
 /* stylelint-disable selector-max-specificity, no-invalid-double-slash-comments */
.sticky-top-belt-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate(0, -100%);
}
body.no-fullscreen {
  .product-intro__add-cart.fixed {
    .add-cart__store-btn,
    .add-cart__wish-btn,
    .mshe-btn-black {
      transition: none;
      margin-bottom: env(safe-area-inset-bottom);
    }
  }
}
body.ipx-fullscreen {
  .product-intro__add-cart.fixed {
    .add-cart__store-btn,
    .add-cart__wish-btn,
    .mshe-btn-black {
      transition: none;
      margin-bottom: 0.906rem;
    }
  }
}
.product-intro__add-cart {
  .flexbox();
  .space-between();
  .zindex-translatez(
    @zindex-fixedregister,
    translate3d(0, 0, @tranlatez-fixedregister)
  );
  position: fixed;
  width: 10rem;
  padding: 0.16rem 0.32rem 0.16rem 0.32rem;
  padding-bottom: calc(0.16rem + 1.8 * constant(safe-area-inset-bottom)); /* 兼容 iOS < 11.2 */
  padding-bottom: calc(0.16rem + 1.8 * env(safe-area-inset-bottom)); /* 兼容 iOS >= 11.2 */
  left: 0 /*rtl:ignore*/;
  right: 0 /*rtl:ignore*/;
  bottom: 0;
  margin: 0 auto;
  background: #fff;
  border-top: 1px solid #e5e5e5;

  &.no-border-style {
    border: none;
  }

  &.beauty-color-add {
    .zindex-translatez(
      @zindex-transform1,
      translate3d(0, 0, @tranlatez-trans-mask2)
    );
  }

  .belt-wrap {
    position: absolute;
    width: 100%;
    left: 0;
    top: -56/75rem;
    .zindex-translatez(
      @zindex-fixedregister,
      translate3d(0, 0, @tranlatez-fixedregister)
    );
  }
}
.add-cart__cart-btn{
  width: 0.85333rem;
  height: 1.07rem;
  text-align: center;
  margin-right: 0.32rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes goodsdt-like-anim-click {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.6;
  }
}
@keyframes goodsdt-like-anim-loading {
  0% {
    transform: scale(0.9);
    opacity: 0.6;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.6;
  }
}
@keyframes goodsdt-like-anim-complete {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes add-board-keyframes {
  0% {
    opacity: 0;
    top: 0;
  }
  100% {
    opacity: 0.8;
    top: -1.1733rem;
  }
}

.self-animated-medium {
  animation-duration:.4s;
  animation-fill-mode: both;
  animation-timing-function: ease;
}
</style>
