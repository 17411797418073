<template>
  <OneClickPayBtn
    :loadImmediately="true"
    :show="showOneClickPayBtn"
    scene="ProductDetail"
    :billInfo="billInfo"
    :goodsData="base_info"
    :quantity="quantity"
    :extra-pay-params="extraOneClickPayParams"
    :before-pay="testBeforeOCP"
    :language="language_v2"
    :analysis-data="oneClickPayAnalysisData"
    @paySuccess="onOneClickPaySuccess"
    @payFail="onOneClickPayFail"
    @transComplete="onOneClickPayTransComplete"
  />
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import OneClickPayBtn from './OneClickPayBtn.vue'
import { parseQueryString } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter'
export default defineComponent({
  components: {
    OneClickPayBtn,
  },
  computed: {
    ...mapState('newProductDetail/common', [
      'addToBagForm',
      'quickShip',
    ]),
    ...mapGetters('productDetail/common', [
      'goods_id',
      'base_info',
      'language_v2',
      'sku_info',
      'mall_code',
      'skc_sale_attr',
      'default_mall_code',
      'store_info',
    ]),
    ...mapGetters('productDetail/addBag', [
      'one_click_pay_info'
    ]),
    ...mapGetters('productDetail/price', [
      'estimated_info',
    ]),
    showOneClickPayBtn() {
      return this.one_click_pay_info?.showOneClickPayBtn
    },
    billInfo() {
      return this.one_click_pay_info?.billInfo || {}
    },
    quantity() {
      return this.addToBagForm?.quantity || 1
    },
    extraOneClickPayParams() {
      const add_source = parseQueryString(location.search)?.add_source
      return {
        sku_code: this.sku_info?.sku_code || '',
        mall_code: this.mall_code,
        amount: this.base_info?.salePrice?.amount || '',
        add_source: add_source ? `detail_${add_source}` : '',
      }
    },
    // 一键购埋点数据
    oneClickPayAnalysisData() {
      const upperBillNo = parseQueryString(location.search)?.billno
      if (typeof window === 'undefined') {
        return null
      }
      return {
        location: 'page',
        order_no: upperBillNo || '',
        activity_from: upperBillNo ? 'one_tap_pay' : '',
      }
    },
  },
  methods: {
    ...mapActions('newProductDetail', [
      'asyncHotModles'
    ]),
    ...mapActions('productDetail/addBag', [
      'open_quick_add_main_product',
    ]),
    // 一键购按钮点击时的检测
    testBeforeOCP() {
      const hasAttrs = this.skc_sale_attr?.length
      if (hasAttrs && !this.sku_info?.sku_code) {
        this.open_quick_add_main_product?.()
        daEventCenter.triggerNotice({
          daId: '1-6-1-91',
          extraData: {
            goods_id: this.goods_id,
            activity_from: 'main',
            button_type: 'one_tap_pay',
          },
        })
        return false
      }
      return true
    },
    updateRealApi() {
      this.asyncHotModles({
        goods_id: this.goods_id,
        mallCode: this.mall_code,
      })    
    },
    // 一键购成功
    async onOneClickPaySuccess(successInfo) {
      this.updateRealApi()
      if (typeof window === 'undefined') {
        return
      }
      let variant = ''
      if (this.skc_sale_attr?.length) {
        this.sku_info?.sku_sale_attr?.forEach((item) => {
          if (item.attr_id == 87) {
            variant = item.attr_value_name
          }
        })
      }
      const threshold = this.estimated_info?.dataType 
      daEventCenter.triggerNotice({
        daId: '1-8-1-1',
        extraData: {
          code: 'goodsDetail',
          result: successInfo?.response || { code: 0 },
          postData: {
            sku: successInfo.reqParams.good_sn,
            spu: this.base_info?.product_relation_id || this.base_info?.goods_sn,
            quickShip: this.quickShip,
            price: this.base_info?.salePrice?.usdAmount,
            catId: this.base_info.cat_id,
            variant,
            discount: this.base_info?.unit_discount,
            index: 0,
            amount: this.base_info?.salePrice?.amount,
            businessModel: this.store_info?.business_model,
            storeCode: this.store_info?.store_code,
            mallCode: this.mall_code,
            is_default_mall: this.default_mall_code == this.mall_code ? 1 : 0,
            // TODO: mallTag 埋点已经不用了
            // mallTag: detail.mall[mallCode].mall_tags,
            location: 'page',
            image_tp: 'small',
            is_customize: 0,
            ...(threshold ? { threshold } : {}),
            ...successInfo.reqParams
          },
          from: 'main',
          review_location: '',
          traceid: window.SaPageInfo?.page_param?.traceid,
          button_type: 'one_tap_pay',
        },
      })     
    },
    // 一键购支付失败回调
    onOneClickPayFail() {
      this.updateRealApi()
      if (typeof window === 'undefined') {
        return
      }
      daEventCenter.triggerNotice({
        daId: '1-8-1-1',
        extraData: {
          code: 'goodsDetail',
          from: 'main',
          review_location: '',
          traceid: window.SaPageInfo?.page_param?.traceid,
          button_type: 'one_tap_pay',
        },
      })
    },
    // 一键购支付会话和交互结束
    onOneClickPayTransComplete(type) {
      // 支付失败，加车
      if (type === 'fail') {
        this.$emit('changeAddBag', null, '', 'one_tap_pay')
      }
    },
  },
})
</script>
