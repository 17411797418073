<script lang="jsx">
import { defineComponent } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { ClientOnly } from '@sheinfe/vue-client-only'
import SwiperSlideItem from 'public/src/pages/components/swiperSlide/SwiperSlideItem.vue'
import CropImageContainerJsx from 'public/src/pages/components/CropImageContainer/CropImageContainerJsx.vue'
import { transformImg } from '@shein/common-function'

export default defineComponent({
  name: 'CarouselsImage',
  inject: {
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
  },
  components: {
    ClientOnly,
    SwiperSlideItem,
    CropImageContainerJsx,
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0
    },
    locals: {
      type: Object,
      default: () => ({})
    }
  },
  render({ $props: props, $slots: slots, $attrs: listeners }) {
    const { cutImg } = this || {}

    const { item, index, locals } = props
    const { fixedRatio, LAZY_IMG, isSsr } = locals || {}
    const baseCutImg = (imgUrl, imgDesignWidth = 0, imgDataExp = {}) => {
      if (!cutImg) {
        return transformImg({ img: imgUrl })
      }
      return cutImg(imgUrl, imgDesignWidth, imgDataExp)
    }
    const _handleGoodsImgClick = (index, item) => {
      listeners?.onClickGoodsImg(index)
      // 主图点击埋点上报
      daEventCenter.triggerNotice({
        daId: '1-6-1-27',
        extraData: { pic: item }
      })
    }

    let dataSrc = item?.origin_image || null
    // 临时兼容
    dataSrc = dataSrc ? baseCutImg( dataSrc?.replace('.webp', '.jpg'), 750) : LAZY_IMG

    if (isSsr) {
      dataSrc = dataSrc?.replace(/(_square)|(_squfix)/ig, '') // 1-1 这种比例防止ssr直出补图样式与客户端不一致
    }

    const isFsIndex = index < 1
    const itemImgClass = () => {
      const result = []
      if (isFsIndex) { // 前一图采用手动补图逻辑
        result.push('fsp-element')
      } else {
        result.push('lazyload')
      }
      return result
    }
    // 临时方案 - webp兼容问题
    const bfCacheImg = item.bfCache ? baseCutImg(item.bfCache?.replace('.webp', '.jpg'), 750) : LAZY_IMG
    const src = isSsr && isFsIndex ? dataSrc : bfCacheImg
    const imageKey = `${item.img_id}_${index}_${item._wk_dynamics && item._wk_dynamics || ''}` // 动态水印刷新图片。防止被缓存
    return (
      <SwiperSlideItem
        key={`${item.img_id}_${index}`} 
        index={index}
        fsp-key={src}
        class="main-picture__slide-item"
        onClick={() => { _handleGoodsImgClick(index, item) }}
      >
        <CropImageContainerJsx
          key={imageKey}
          fixedRatio={fixedRatio}
          imgSrc={dataSrc}
          imgProps={{
            src,
            class: itemImgClass(),
            dataDesignWidth: '750'
          }}
        />

        {/* 主图Beauty品类蒙版 */}
        {slots.fistMask?.() || null}
        {/* 主图角标 */}
        {slots.locateLabels?.() || null}
      </SwiperSlideItem>
    )
  },
})

</script>
