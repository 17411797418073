<template>
  <button
    ref="brandDealsBtn"
    class="brand-deals-btn"
    :class="{ 'disabled': data.triggerLimit}"
    :disabled="data.triggerLimit"
    @click="handleClick"
  >
    {{ data.mainBtnText }}
  </button>
</template>

<script setup name="BrandDealsBtn">
import { onMounted, ref } from 'vue'
import OneLink from 'public/src/pages/goods_detail_v2/utils/oneLink.js'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
})

let oneLink = null
const brandDealsBtn = ref(null)
onMounted(() => {
  const { activityId, oneLinkUrl } = props.data
  oneLink = new OneLink(oneLinkUrl, activityId)
  oneLink.bindCopyHandle(brandDealsBtn.value)
})

const handleClick = async () => {
  oneLink.navigateToApp()
}
</script>

<style lang="less" scoped>
.brand-deals-btn {
  width: 100%;
  border: none;
  background-color: #222222;
  font-weight: 700;
  font-size: 16px;
  line-height: .5067rem /* 19/37.5 */;
  color: #FFFFFF;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 .4rem /* 15/37.5 */;
}

.disabled {
  color: #E5E5E5;
  background-color: #BBBBBB;
}
</style>
