<script lang="jsx">
import { defineComponent, nextTick } from 'vue'
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import ColorTitle from 'public/src/pages/goods_detail_v2/innerComponents/top/MainSaleAttr/ColorTitle.vue'
import ColorV2 from './ColorV2.vue'
import { throttle } from '@shein/common-function'
import { expose, tap } from 'public/src/pages/common/analysis/directive'
import { OPEN_QUICK_ADD_TYPE } from 'public/src/pages/product_app/store/modules/product_detail/components/top/innerComponents/constant.js'

export default defineComponent({
  name: 'MainSaleAttr',
  directives: {
    expose,
    tap,
  },
  components: {
    ColorTitle,
  },
  props: {
    // TODO 看起来是废弃了
    // Apollo 控制补图
    isSupportCropImage: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return { 
      hasLeftCover: true,
    }
  },
  computed: {
    ...mapState('productDetail/MainSaleAttr', ['last_click_color']),
    ...mapGetters('productDetail/MainSaleAttr', [
      'main_sale_attr_init', 
      'colors_info', 
      'color_type',
      'prepose_have_scroll',
      'is_skc_prepose',
      'skc_prepose_val',
      'prepose_color_type',
      'prepose_have_scroll',
      'hot_goods_sn_list',
      'is_show_attrnewicon',
    ]),
    ...mapGetters('productDetail/common', [
      'language_v2',
      'goods_id',
      'common_page_info',
      'is_use_bff_fs'
    ]),
    ...mapGetters('newProductDetail/common', ['allDataReady']),
    // TODO: 旧数据源，后续迁移
    ...mapState('newProductDetail/common', ['loading', 'fixedRatio']),
    getaAnaData(){
      const color_list = this.colors_info.map(item => {
        let icon_type = ''
        const { isEco, isNew, hot_color, lowPrice, isShowThickening } = item || {}
        if (isEco == '1') {
          icon_type = 'evo'
        }else if (isNew == '1' && this.is_show_attrnewicon) {
          icon_type = 'new'
        }else if(lowPrice) {
          icon_type = 'low_price'
        }else if(hot_color == '1') {
          icon_type = 'hot'
        } else if (isShowThickening == '1') {
          icon_type = 'thickening'
        }
        // let icon_type = 
        // item.isEco == '1' ? 'evo' :
        //   item.isNew == '1' && this.is_show_attrnewicon ? 'new' :
        //     item.hot_color == '1' ? 'hot' : ''

        item.icon_type = icon_type
        return {
          color: item.goods_id,
          icon_type: item.icon_type,
          is_sold_out: item.isSoldOutStatus === '1' ? '1' : '0',
        }
      })
      const { 
        isShowColorMoreIcon, 
        supportLargeImageList,
      } = this.main_sale_attr_init
      return {
        id: '1-6-1-31', 
        waitReady: true,
        data: { 
          color_list,
          if_have_mainattr_icon: this.is_skc_prepose || !supportLargeImageList || !isShowColorMoreIcon ? 0 : 1,
          if_have_switch_image_entry: 0,
          location: 'page', 
          image_tp: 'small', 
          is_front: this.is_skc_prepose ? 1 : 0 
        }
      }
    }
  },
  watch: {
    goods_id: {
      handler() {
        if (this.is_skc_prepose) {
          nextTick(() => {
            this.colorIntoView()
          })
        }
      }
    },
  },
  mounted() {
    const { main_sale_attr_init } = this
    const { isShowFindMyShadeEntry } = main_sale_attr_init || {}
    nextTick(() => {
      // find my shade曝光
      if(isShowFindMyShadeEntry) {
        daEventCenter.triggerNotice({
          daId: '1-6-4-32'
        })
      }
      
      if (this.is_skc_prepose) {
        this.colorIntoView()
        const scrollContainer = document.getElementsByClassName('goods-color__list-box')?.[0]
        const handleScroll = throttle({
          func: () => {
            if (scrollContainer?.scrollWidth - scrollContainer?.clientWidth - scrollContainer?.scrollLeft <= 10 || this.skc_prepose_val === 'C') {
              this.hasLeftCover = false
            }else {
              this.hasLeftCover = true
            }
          },
          wait: 50
        })
        scrollContainer?.addEventListener('scroll', handleScroll)
      }
    })
    // appEventCenter.on('close-popover-add-bag', this.switchColorFn)
  },
  beforeUnmount() {
    // appEventCenter.off('close-popover-add-bag', this.switchColorFn)
  },
  methods: {
    ...mapActions('productDetail/common', ['switch_color']),
    ...mapActions('productDetail/addBag', [
      'open_quick_add_main_product',
    ]),
    // TODO: 旧数据流
    ...mapMutations('newProductDetail/common', ['updateQuickAddState', 'updateQuickAddLargeImgMode']),
    switchColorFn(color) {
      this.switch_color({
        ...color,
        jumpCallBack: (url) => {
          this.$router.replace(url)
        }
      })
    },
    clickShowFindMyShade() {
      import('public/src/pages/goods_detail_v2/innerComponents/drawer/find_shade/index.js').then(res => {
        res?.default?.show(this.main_sale_attr_init?.findMyShadeParams || {})
      })
    },
    onLargeImageSwitch() {
      const { supportLargeImageList } = this.main_sale_attr_init || {}
      if (!supportLargeImageList) return
      daEventCenter.triggerNotice({ daId: '1-6-1-101' })

      // TODO: 兼容旧数据流更新 大图模式 + 加车弹窗
      if (this.is_use_bff_fs) {
        this.open_quick_add_main_product({
          screen: OPEN_QUICK_ADD_TYPE.LARGE_IMAGE_SWITCH
        })
      } else {
        this.updateQuickAddState(true)
        this.updateQuickAddLargeImgMode(true)
      }

    },
    colorIntoView() {
      if (typeof window === 'undefined') return
      var element = document.getElementsByClassName('goods-color__prepose-item color-active')[0]
      const scrollContainer = document.getElementsByClassName('goods-color__wrap')?.[0]
      const container = document.getElementsByClassName('goods-color__list-box')?.[0]
      if(!element || !scrollContainer || !container){
        return 
      }
      const elementRect = element.getBoundingClientRect()
      const absoluteElementLeft = elementRect.left + scrollContainer.scrollLeft
      const middleX = absoluteElementLeft - (container.clientWidth / 2) + (elementRect.width / 2)
      
      container.scrollTo({
        left: middleX,
        behavior: 'smooth' // 使用平滑滚动
      })
    },
  },
  render() {
    const { 
      isShowColorMoreIcon, 
      colorBlockText, 
      supportLargeImageList,
      isShowFindMyShadeEntry,
      mainSaleAttrDescText,
      mainAttrPicInfo,
      showColorsTag,
    } = this.main_sale_attr_init || {}
    const renderTitle = () => {
      if (this.is_skc_prepose) return null
      return <ColorTitle
        isShowColorMoreIcon={isShowColorMoreIcon}
        language={this.language_v2}
        colorBlockText={colorBlockText}
        supportLargeImageList={supportLargeImageList}
        isShowFindMyShadeEntry={isShowFindMyShadeEntry}
        largeImageSwitch={this.onLargeImageSwitch}
        clickShowFindMyShade={this.clickShowFindMyShade}
      />
    }

    const renderColorSelectBox = () => {
      return <ColorV2
        language={this.language_v2}
        mainGoodsId={this.goods_id}
        mainSaleAttrDescText={mainSaleAttrDescText}
        mainAttrPicInfo={mainAttrPicInfo}
        colorsInfo={this.colors_info}
        eventCategory={'商品详情页'}
        skcPreposeVal={this.skc_prepose_val}
        preposeColorType={this.prepose_color_type}
        from={'page'}
        colorType={this.color_type}
        isSkcPrepose={this.is_skc_prepose}
        lastClickColor={this.last_click_color}
        loading={this.loading}
        fixedRatio={this.fixedRatio}
        isSupportCropImage={this.isSupportCropImage}
        switchColor={this.switchColorFn}
        lazyImg={this.common_page_info?.LAZY_IMG || ''}
        isShowAttrNewIcon={this.is_show_attrnewicon}
        showColorsTag={showColorsTag}
      />
    }

    const renderPrePoseLarge = () => {
      // 销售属性前置展示大图入口
      if (!supportLargeImageList || !this.is_skc_prepose) return null
      return <div 
        class={{ 'goods-color-prepose__right-arrow': this.skc_prepose_val !== 'C', 'goods-color-prepose__right-arrow-c': this.skc_prepose_val === 'C' }}
        v-expose={{ id: '1-6-1-79' }}
        v-tap={{ id: '1-6-1-80' }}
      >
        {this.hasLeftCover && <div class="goods-color-prepose__left-cover"></div>}
        {
          this.skc_prepose_val === 'C' ? 
            <div class={{ 'goods-color-prepose__right-cover-c': true, 'goods-color-prepose__right-cover-length': this.prepose_have_scroll }}>
              { this.prepose_have_scroll ? this.colors_info.length : null}
              <i class="suiiconfont sui_icon_more_right_16px" onClick={this.onLargeImageSwitch}></i>
            </div> : 
            <div class="goods-color-prepose__right-cover">
              <i class="suiiconfont sui_icon_more_right_16px" onClick={this.onLargeImageSwitch}></i>
            </div>
        }
        
      </div>
    }

    const classText = this.is_skc_prepose ? 'goods-color-prepose' : 'goods-color'
    const skcClassText = this.prepose_have_scroll ? 'goods-color-thumbnail' : ''
    return <div
      id="goods-color-main"
      class={[classText, skcClassText, 'NEW_BFF_DETAIL_COMPONENT']}
      v-expose={this.getaAnaData}
      data-ready={this.allDataReady}
    >
      { renderTitle() }
      { renderColorSelectBox() }
      { renderPrePoseLarge() }
    </div>
  }
})
</script>

<style lang="less">
.goods-color {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .selected-color {
      display: inline-flex;
      align-items: center;
      // line-height: 1;
      line-height: normal;
      margin-bottom: 4px;
    }

    .color-block, i {
      display: inline-block;
      font-style: normal;
      font-weight: 700;
      .font-dpr(28px);

      /* rw:begin */
      font-family: Adieu;
    }
  }

  &__shade {
    display: inline-block;
    color: #1860A7;
    .font-dpr(24px);
    text-decoration: none;
  }
}

.goods-color-prepose {
  position: relative;
  padding-right: 0.533rem;
  margin-bottom: 0.266rem;
  &.goods-color-thumbnail{
    padding-right: 1.3333rem;
  }
  .goods-color__list-box {
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__left-cover{
    background: linear-gradient(270deg, #FFF -3.13%, rgba(255, 255, 255, 0.00) 103.13%);
    width: 0.64rem;
    height: 100%
  }
  &__right-cover{
    width: 0.533rem;
    background-color: #FFF;
    height: 1.06rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #959595
  }
  &__right-cover-c{
    margin-top: 0.2667rem;
    background-color: #FFF;
    border-radius: 2.667rem;
    height: 0.64rem;
    border: 1px solid var(---sui_color_gray_dark1, #000);
    display: flex;
    align-items: center;
    width: 0.64rem;
    justify-content: center;
    color: var(---sui_color_gray_dark1, #000);
    margin-left: 0.16rem;
  }
  &__right-cover-length{
     width: 1.1733rem;
     padding: 0.16rem 0.16rem 0.16rem 0.2677rem;
  }
  &__right-arrow {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: auto;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  &__right-arrow-c {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFF;
    z-index: 2;
    .goods-color-prepose__left-cover{
      display: none;
    }
  }
  .goods-color__imgs{
    padding: .266rem 0 .213rem;
  }

  .goods-color__soldout{
    border: 1px dashed @sui_color_gray_weak1;
  }
}
</style>
