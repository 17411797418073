<script lang="jsx">
import { defineComponent } from 'vue'
import { containerRatio } from './utils'

export default defineComponent({
  name: 'CropImageContainerJsx',

  props: {
    imgSrc: {
      required: true,
      type: String,
      default: ''
    },
    containerBackground: {
      type: String,
      default: '#F6F6F6'
    },
    fixedRatio: {
      type: String,
      default: ''
    },
    imgProps: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  render({ $props: props }) {
    const { imgSrc, containerBackground, fixedRatio, imgProps } = props
    
    // 商品中心原图
    const originImgSrc = () => {
      // 商品中心原图(不带后缀)
      return imgSrc?.replace(/(_square)|(_squfix)/ig, '')
    }
    return (
      <div
        class="crop-image-container"
        style={{
          backgroundColor: `${containerBackground}`,
          paddingBottom: `calc(${containerRatio(fixedRatio, imgSrc).toFixed(3)} * 100%)`
        }}
        data-before-crop-src={imgSrc}
      >
        <img
          id={imgProps?.id}
          class={['crop-image-container__img', imgProps?.class]}
          data-src={originImgSrc()}
          src={imgProps?.src || originImgSrc()}
          data-design-width={imgProps?.dataDesignWidth}
          data-exp={imgProps?.dataExp}
        />
      </div>
    )
  },
})
</script>

<style lang="less">
.crop-image-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 0;

  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: var(--crop-img-fit, contain);
  }
}
</style>
