<script lang="jsx">
import { defineComponent } from 'vue'
/**
 * 根据角标类型/方位添加样式
 */
const getLabelsStyle = (label) => {
  const { subscriptType, _position = {} } = label || {}
  const { inUpper, inLower, inLeft, inRight } = _position

  //  文本类型角标添边距
  const space = subscriptType === 'text' ? '.32rem' : '0'

  return {
    top: inUpper ? space : 'auto',
    bottom: inLower ? space : 'auto',
    left: inLeft ? '0' : 'auto',
    right: inRight ? '0' : 'auto',

    position: 'absolute',
  }
}

/**
 * 主图角标组件
 */
export default defineComponent({
  name: 'LocateLabels',
  props: {
    locateLabelFormatData: {
      type: Array,
      default: () => []
    }
  },
  render({ $props: props }) {
    const { locateLabelFormatData } = props
    if (!locateLabelFormatData?.length) return null



    const renderLabels = ({ label, flexibleLabel } = {}) => {

      // 渲染基础角标
      const _renderLabelItem = (label, { isWrap = true } = {}) => {
        const { premiumFlagPosition, tagColor = '#fff', bgColor = '#000' } = label || {}

        const itemStyle = isWrap ? getLabelsStyle(label) : {}

        //  图片类型角标
        if (label.subscriptType === 'image') {
          return (
            <img
              class="main-picture__labels-images not-fsp-element"
              style={itemStyle}
              key={premiumFlagPosition}
              src={label.icon}
            />
          )
        }

        //  文本类型角标
        if (label.subscriptType === 'text') {
          return (
            <div
              class="main-picture__labels-text not-fsp-element"
              style={itemStyle}
              key={premiumFlagPosition}
            >
              <p class="box"
                style={{
                  color: tagColor, // 字体类型角标样式
                  background: bgColor
                }}
              >{label.tagName}</p>
            </div>
          )
        }

        return null
      }

      // 渲染灵活角标
      const _renderFlexibleLabelItem = (label, { isWrap = true, afterText, afterImage } = {}) => {
        const { premiumFlagPosition } = label || {}
        return (
          <div
            class={{
              'main-picture__labels-flexible': true,
              'not-fsp-element': true,
              'after-text': afterText,
              'after-image': afterImage,
            }}
            style={isWrap ? getLabelsStyle(label) : {}}
            key={premiumFlagPosition}
          >
            <p class="box">{label.tagName}</p>
          </div>
        )
      }

      const hasMulitLabel = label && flexibleLabel

      // 只存在一个角标
      if (!hasMulitLabel) {
        if (label) return _renderLabelItem(label)
        if (flexibleLabel) return _renderFlexibleLabelItem(flexibleLabel)
      }

      // 存在多个角标
      return (
        <div
          class="not-fsp-element"
          key={label.premiumFlagPosition}
          style={getLabelsStyle(label)}>
          {_renderLabelItem(label, { isWrap: false })}
          {/* 灵活角标在下面 */}
          {_renderFlexibleLabelItem(flexibleLabel, {
            isWrap: false,
            afterText: label.subscriptType === 'text',
            afterImage: label.subscriptType === 'image',
          })}
        </div>
      )
    }
    return <div>
      {
        locateLabelFormatData.map((labels) => {
          return renderLabels(labels)
        })
      }
    </div>
  }
})
</script>

<style lang="less" scoped>
@space-text : .32rem;
@space-img  : 0px;

.main-picture {
  &__labels-images {
    width: 100%;
    height: auto;
  }

  &__labels-text {
    display: flex;
    justify-content: flex-end;
    .box {
      width: auto;
      height: auto;
      padding: .0534rem .16rem;
      line-height: .4533rem /* 17/37.5 */;
      font-size: 14px;
      min-width: .89rem;
    }
  }

  &__labels-flexible { // 灵活角标
    display: flex;
    justify-content: flex-end;
    padding-right: 0.16rem;

    .box {
      height: 0.51rem;
      padding: 0rem 0.16rem;
      background: radial-gradient(50% 50% at 50% 50%, #FFF 0%, rgba(255, 255, 255, 0.80) 100%);
      border-radius: 0.8rem;

      font-size: 11px;
      line-height: 0.51rem;
    }

    &.after-text {
      margin-top: 0.16rem;
    }

    &.after-image {
      position: absolute;
      top: 70%;
      right: 0;
    }
  }
}
</style>

