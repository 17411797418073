<template>
  <div
    :id="domIds.container"
    class="productPrice"
    :data-is-floor-type="isFloorType"
  >
    <template v-if="pricePrefixInfo.isShow">
      <PricePrefix
        :value="pricePrefixInfo.value"
        :style="{ color: mainPrice.color.font_color }"
      />
      <div class="productPrice__gap-4px"></div>
    </template>
    <!-- 加车弹窗价格 还没加呢. 得去下沉组件里去改加车的价格 -->
    <template v-if="priceFrom.isShowMainPriceLeft">
      <PriceFrom
        :value="priceFrom.value"
        :style="{ color: mainPrice.color.font_color }"
      />
      <div class="productPrice__gap-2px"></div>
    </template>
    <div
      :id="domIds.mainPrice"
      class="productPrice__main"
      :style="{ color: mainPrice.color.font_color }"
      v-html="mainPrice.valueHtml"
    >
    </div>
    <template v-if="priceFrom.isShowMainPriceRight">
      <div class="productPrice__gap-2px"></div>
      <PriceFrom
        :value="priceFrom.value"
        :style="{ color: mainPrice.color.font_color }"
      />
    </template>

    <template v-if="unitPriceInfo">
      <div class="productPrice__gap-4px"></div>
      <UnitPrice
        :unit-price-info="unitPriceInfo"
        :style="{ color: mainPrice.color.font_color }"
      />
    </template>

    <template v-if="exclusiveInfo && exclusiveInfo.isS3newpriceAbtNew">
      <div class="productPrice__gap-2px"></div>
      <S3TagNew data-graph-type />
    </template>

    <div class="productPrice__gap-2px"></div>
    <PriceVat
      v-if="priceVatInfo"
      data-graph-type
      :price-vat-info="priceVatInfo"
      :is-floor-type="isFloorType"
      :config="config"
    />
    <!-- 折扣金额 -->
    <template v-if="discountAmountInfo && !!discountAmountInfo.discountValue">
      <div class="productPrice__gap-4px"></div>
      <DiscountAmount
        data-graph-type
        :discount-amount-info="discountAmountInfo"
      />
      <div class="productPrice__gap-2px"></div>
    </template>

    <div class="productPrice__gap-2px"></div>

    <!-- 合规站点 法国德国 RRP  -->
    <template v-if="recommendRetailPriceFrDe">
      <RecommendRetailPriceFrDe
        :recommend-retail-price-fr-de="recommendRetailPriceFrDe" 
        :is-floor-type="isFloorType"
        :discount-amount-info="discountAmountInfo"
        :config="config"
      />
    </template>
    <ClientOnly>
      <!-- 非合规站点 discount info  -->
      <DiscountInfo 
        v-if="discountInfo.show && canMount"
        :id="domIds.discountInfo"
        :retail-price="discountInfo.retailPrice"
        :discount-percent="discountInfo.discountPercent"
        :discount-amount-info="discountAmountInfo"
        :assign-retail-style="discountInfo.retailPriceStyle"
        :discount-percent-assign-style="discountInfo.discountPercentStyle"
        :countdown-info="countdownInfo"
        :main-price-color="mainPrice.color"
        :is-wrap="discountInfoIsWrap"
        :style="graphTypeBeforeStyle"
        :config="config"
      />

      <!-- 合规站点 discount info  -->
      <template v-if="suggestedSalePriceInfo.show">
        <!-- 法国fr -->
        <DiscountInfo 
          v-if="suggestedSalePriceInfo.fr.show"
          :assign-retail-style="config.priceStyleType.showBrandDeals ? discountInfo.retailPriceStyle : {'font-size': '11px'}"
          :retail-price="suggestedSalePriceInfo.fr.value"
          :discount-percent="suggestedSalePriceInfo.fr.discountPercent"
          :discount-amount-info="discountAmountInfo"
          :discount-percent-assign-style="discountInfo.discountPercentStyle"
          :style="graphTypeBeforeStyle"
          :config="config"
        />
        <!-- 波兰pl -->
        <DiscountInfoPl
          v-if="suggestedSalePriceInfo.pl.show"
          :retail-price="suggestedSalePriceInfo.pl.value"
          :discount-percent="suggestedSalePriceInfo.pl.discountPercent"
          :discount-amount="suggestedSalePriceInfo.discountAmount"
          :discount-amount-info="discountAmountInfo"
          :discount-percent-assign-style="discountInfo.discountPercentStyle"
          :assign-retail-style="discountInfo.retailPriceStyle"
          :retail-price-tip="suggestedSalePriceInfo.pl.tip"
          :config="config"
        />
        <!-- 德国de -->
        <template v-if="suggestedSalePriceInfo.de.show">
          <DiscountInfoDe
            v-if="suggestedSalePriceInfo.de.retailPriceShow"
            :assign-style="{
              ...discountInfoDeAssignStyle,
            }"
            :retail-price="suggestedSalePriceInfo.de.retailPrice"
            :discount-percent="suggestedSalePriceInfo.de.retailPriceDiscountPercent"
            :discount-amount="suggestedSalePriceInfo.discountAmount"
            :discount-amount-info="discountAmountInfo"
            :discount-percent-assign-style="discountInfo.discountPercentStyle"
            :retail-price-tip="suggestedSalePriceInfo.de.retailTip"
            :style="graphTypeBeforeStyle"
            :config="{
              ...config,
              priceType: 'retailPriceSale'
            }"
          />
          <DiscountInfoDe
            :assign-style="{
              width: suggestedSalePriceInfo.de.retailPriceShow ? '9rem' : '',
              'margin-top': suggestedSalePriceInfo.de.retailPriceShow ? '.1067rem' : '',
              ...discountInfoDeAssignStyle,
            }"
            :retail-price="suggestedSalePriceInfo.de.value"
            :discount-percent="suggestedSalePriceInfo.de.discountPercent"
            :discount-amount="suggestedSalePriceInfo.discountAmount"
            :discount-amount-info="discountAmountInfo"
            :discount-percent-assign-style="discountInfo.discountPercentStyle"
            :retail-price-tip="suggestedSalePriceInfo.de.valueTip"
            :config="config"
            data-no-insert="1"
          />
        </template>
      </template>
    </ClientOnly>

    <template v-if="recommendRetailPrice">
      <div class="productPrice__gap-4px"></div>
      <!-- 波兰建议零售价 -->
      <RecommendRetailPrice
        :is-floor-type="isFloorType"
        :recommend-retail-price="recommendRetailPrice"
        :discount-amount-info="discountAmountInfo"
        :config="config"
      />
    </template>

    <div class="productPrice__gap-4px"></div>

    <S3Tag
      v-if="exclusiveInfo && !exclusiveInfo.isS3newpriceAbtNew"
      data-graph-type
      :exclusive-info="exclusiveInfo"
    />
    <ClientOnly>
      <template v-if="estimatedInfo">
        <EstimatedTag
          :id="domIds.estimatedTag"
          :estimated-info="estimatedInfo"
          :is-wrap="estiamtedTagIsWrap"
          :countdown-info="countdownInfo"
          :config="estimatedConfig"
          data-graph-type
          @clickEstimatedTag="emits('clickEstimatedTag')"
        />
        <template v-if="estimatedInfo.retailPrice">
          <div
            v-if="!(discountAmountInfo && !!discountAmountInfo.discountValue)"
            class="productPrice__gap-4px"
          ></div>
          <EstimatedTagNewRetail
            :estimated-info="estimatedInfo"
            @clickEstimatedTag="emits('clickEstimatedTag')"
          />
        </template>
      </template>
    </ClientOnly>
  </div>
</template>

<script setup name="PriceContainer">
/**
 * @components Price 组件不引用 vuex，纯组件
*/
import { computed, onMounted, defineProps, defineAsyncComponent, nextTick, watch, toRefs } from 'vue'
import { debounce } from '@shein/common-function'
import { markPoint } from 'public/src/services/mark/index.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { onUnmounted } from 'vue'
import useCalcDomFn from './hooks/calcDomFn.js'
daEventCenter.addSubscriber({ modulecode: '1-6-1' })

const PricePrefix = defineAsyncComponent(() => import('./components/PricePrefix.vue'))
const PriceFrom = defineAsyncComponent(() => import('./components/PriceFrom.vue'))
const PriceVat = defineAsyncComponent(() => import('./components/PriceVat.vue'))
const UnitPrice = defineAsyncComponent(() => import('./components/UnitPrice.vue'))
const S3Tag = defineAsyncComponent(() => import('./components/S3Tag.vue'))
const S3TagNew = defineAsyncComponent(() => import('./components/S3TagNew.vue'))
const EstimatedTag = defineAsyncComponent(() => import('./components/EstimatedTag/index.vue'))
const EstimatedTagNewRetail = defineAsyncComponent(() => import('./components/EstimatedTag/components/EstimatedTagNewRetail.vue'))
const DiscountInfo = defineAsyncComponent(() => import('./components/discountInfo/DiscountInfo.vue'))
const DiscountInfoPl = defineAsyncComponent(() => import('./components/discountInfo/DiscountInfoPl.vue'))
const DiscountInfoDe = defineAsyncComponent(() => import('./components/discountInfo/DiscountInfoDe.vue'))
const RecommendRetailPrice = defineAsyncComponent(() => import('./components/RecommendRetailPrice.vue'))
const RecommendRetailPriceFrDe = defineAsyncComponent(() => import('./components/RecommendRetailPriceFrDe.vue'))
const DiscountAmount = defineAsyncComponent(() => import('./components/DiscountAmount.vue'))

const props = defineProps({
  pricePrefixInfo: {
    type: Object,
    default: () => ({})
  },
  countdownInfo: {
    type: Object,
    default: () => ({})
  },
  priceFrom: {
    type: Object,
    default: () => ({})
  },
  mainPriceInfo: {
    type: Object,
    default: () => ({})
  },
  priceVatInfo: {
    type: Object,
    default: () => ({})
  },
  discountAmountInfo: {
    type: Object,
    default: () => ({})
  },
  discountInfo: {
    type: Object,
    default: () => ({})
  },
  estimatedInfo: {
    type: Object,
    default: () => ({})
  },
  suggestedSalePriceInfo: {
    type: Object,
    default: () => ({})
  },
  recommendRetailPrice: {
    type: Object,
    default: () => ({})
  },
  recommendRetailPriceFrDe: {
    type: Object,
    default: () => ({})
  },
  exclusiveInfo: {
    type: Object,
    default: () => ({})
  },
  unitPriceInfo: {
    type: Object,
    default: () => ({})
  },
  config: {
    type: Object,
    default: () => ({
      scene: 'MAIN',
      realTimeReady: false,
      priceStyleType: {
        showBrandDeals: false,
      }
    })
  },
  canMount: {
    type: Boolean,
    default: true
  }
})


const emits = defineEmits(['clickEstimatedTag'])

const { mainPriceInfo, config, discountAmountInfo } = toRefs(props)

const mainPrice = computed(() => {
  const { priceStyleType = {}, scene } = config.value || {}
  const { color } = mainPriceInfo.value
  return {
    ...mainPriceInfo.value,
    // 百补样式
    ...(priceStyleType?.showBrandDeals && scene === 'MAIN' ? {
      color: {
        ...color,
        font_color: '#FFF'
      }
    } : {})
  }
})

const estimatedConfig = computed(() => {
  const { priceStyleType = {} } = config.value || {}
  const { isHitComplianceMode, discountValue } = discountAmountInfo.value || {}
  // 到手价 && 百补活动 && 非合规站点屏蔽折扣率
  if (props.estimatedInfo && priceStyleType?.showBrandDeals && !isHitComplianceMode) {
    return {
      hideDiscountPercent: discountValue?.type === 'estimatedPrice'
    }
  }
  return {}
})

const SCENE_MAP = {
  MAIN: 'MAIN', // 主价格
  FLOOR: 'FLOOR', // 悬浮
  SIMPLE: 'SIMPLE', // 简易
}

const isFloorType = computed(() => props.config.scene === SCENE_MAP.FLOOR)

const discountInfoDeAssignStyle = computed(() => {
  const { priceStyleType = {} } = config.value || {}
  if (priceStyleType.showBrandDeals) {
    return {
      color: !isFloorType.value ? '#ffffffcc' : '#767676',
      'align-items': !isFloorType.value ? 'flex-end' : 'align-items',
    }
  }
  return {}
})

const graphTypeBeforeStyle = computed(() => {
  const { priceStyleType = {} } = config.value || {}
  const scenes = [
    !!priceStyleType.showBrandDeals,
    !!(props.exclusiveInfo && props.exclusiveInfo.isS3newpriceAbtNew),
    !!props.priceVatInfo
  ]
  if (scenes.includes(true)) {
    return {
      'align-self': 'center',
    }
  }
  return null
})

watch(
  [() => props.estimatedInfo, () => props.estimatedInfo?.otherInfo?.realTimeReady],
  async () => {
    await nextTick()
  }
)

onUnmounted(() => {
  window._exposeEstimatedTagFn_ = undefined
})
onMounted(() => {
  nextTick(() => {
    requestAnimationFrame(() => {
      markPoint({ eventName: 'renderCycle', measureFrom: 'setPageDataNextTick' })
    })
  })
  if (props.suggestedSalePriceInfo.show && props.config.scene === SCENE_MAP.MAIN) {
    daEventCenter.triggerNotice({ daId: '1-6-1-217' })
  }

  if(typeof window !== 'undefined') {
    window._exposeEstimatedTagFn_ = debounce({
      func: (args) => {
        if (isFloorType.value) return
        daEventCenter.triggerNotice(args)
      },
      wait: 1500
    })
  }
})

const {
  discountInfoIsWrap,
  estiamtedTagIsWrap,
  domIds
} = useCalcDomFn({
  realTimeReady: computed(() => props.config.realTimeReady),
  isFloorType
})

</script>

<style lang="less">
.productPrice {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  max-width: 100%;
  min-width: 0;
  position: relative;

  &__from {
    line-height: 1;
  }

  &__main {
    font-weight: bold;
    direction: ltr /*rtl:ignore*/;
    color: #222;
    line-height: 1;
  }

  &__discount {
    display: flex;
    align-items: center;
    max-width: 100%;
  }

  &__estimated {
    display: flex;
  }

  &__gap-2px {
    height: .32rem;
    width: .0533rem;
  }

  &__gap-4px {
    height: .32rem;
    width: .1067rem;
  }
}
</style>
