import Clipboard from 'clipboard'
import attributionAnalysis from '@shein-advertising/attribution-analysis'
import schttp from 'public/src/services/schttp'
import { OestClientHandle } from '@shein-aidc/basis-oesthandle'

class OneLink {
  copyHandle = null
  oneLinkUrl = ''
  activityId = ''
  copyBoard = ''

  constructor(url, id) {
    this.oneLinkUrl = url
    this.activityId = id
  }

  /**
	 * 获取剪贴板格式
	 */
  async getClipboardContent(activityId) {
    const res = await schttp({
      method: 'POST',
      url: '/api/link/deeplink/getStyleString',
      data: {
        activityId
      }
    })
    return res.info?.info?.styleString || ''
  }

  /**
	 * 是否是ios
	 */
  isIos() {
    return typeof navigator !== 'undefined' && /iPhone|iPad|iPod/i.test(navigator.userAgent)
  }

  /**
	 * 绑定剪贴板 (仅ios)
	 */
  async bindCopyHandle(element) {
    if (!element && !this.isIos()) {
      return
    }

    this.copyBoard = await this.getClipboardContent(this.activityId)
    this.copyHandle = new Clipboard(element, {
      text: () => this.copyBoard
    })

    this.copyHandle.on('success', () => {
      console.log('🚀 ~ OneLink ~ this.copyHandle.on ~ 复制成功')
    })
    this.copyHandle.on('error', () => {
      console.log('🚀 ~ OneLink ~ this.copyHandle.on ~ 复制失败')
    })
  }

  /**
	 * 来源上报 (归因上报，用于IOS安装后获取数据)
	 */
  async reportSource(url, id, copyBoard) {
    if (this.isIos()) {
      return attributionAnalysis({
        onelink: url,
        activityId: id,
        copyBoard: copyBoard || '',
        productName: 'goods-details-add-cart',
        keyId: OestClientHandle.getEnptValue() || ''
      })
    }
  }

  /**
	 * 跳转到app
	 */
  async navigateToApp() {
    const { oneLinkUrl, activityId, copyBoard } = this
    if (!oneLinkUrl) {
      return
    }
    // 归因上报
    await this.reportSource(oneLinkUrl, activityId, copyBoard)
    // 跳转
    window.location.href = oneLinkUrl
  }
}

export default OneLink
