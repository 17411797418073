<script lang="jsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'AddToCartBarPlaceholder',
  props: {
    highLight: {
      type: Boolean,
      default: false
    },
    defaultText: {
      type: String,
      default: 'ADD TO CART'
    }
  },
  render({ $props: props }) {
    const { highLight = false, defaultText } = props
    return (
      <div class="cart-bar-placeholder">
        <div class={[
          'cart-bar-placeholder_wish-btn',
          {
            'cart-bar-placeholder_wish-black': highLight
          }
        ]}>
          <i class="suiiconfont sui_icon_save_32px"></i>
        </div>
        <div class="cart-bar-placeholder_btn">
          <div class={[
            'cart-bar-placeholder_btn-normal',
            {
              'cart-bar-placeholder_btn-black': highLight
            }
          ]}>
            { highLight ? <span>{ defaultText }</span> : '' }
          </div>
        </div>
      </div>
    )
  },
})
</script>

<style lang="less">
.cart-bar-placeholder {
  display: flex;
  padding: 0.16rem 0.32rem;
  margin: 0 auto;
  background: #fff;
  border-top: 1px solid #e5e5e5;
  justify-content: space-between;
  z-index: @zindex-header;
  position: fixed;
  width: 10rem;
  bottom: 0;

  &_wish-btn {
    display: flex;
    align-items: center;
    // flex: 0 0 1.07rem;
    padding: 0 0.17rem;
    text-align: center;
    margin-right: 0.2rem;
    height: 1.07rem;
    color: #959595;
  }

  &_wish-black {
    color: @sui_color_black !important; /* stylelint-disable-line declaration-no-important */
  }

  &_btn {
    display: flex;
    width: auto;
    flex: 1;
    height: 1.07rem;
  }

  &_btn-normal {
    flex: 1;
    height: 100%;
    width: fit-content;
    background: #959595;
  }

  &_btn-black {
    background: @sui_color_brand !important; /* stylelint-disable-line declaration-no-important */
    text-align: center;
    line-height: 1.07rem;
    color: @sui_color_white;
    .font-dpr(32px);
    font-weight: 700;
  }
}
</style>
