<template>
  <div
    v-show="visible"
    class="low-stock-banner"
  >
    <img
      class="low-stock-banner__icon"
      src="https://img.ltwebstatic.com/images3_ccc/2024/12/16/e4/1734316697a93f08d2899f8523ac44f594a8da85a1.png"
      alt="low-stock-icon"
    />
    <p
      v-expose="{
        id: '1-6-1-235',
        data: {
          goods_level: will_sold_out_tips_info.scene,
          label_type: will_sold_out_tips_info.lowStockType == 1 ? 2 : 1,
          location: 'bottomBanner',
        },
      }" 
      class="low-stock-banner__content"
    >
      <span class="low-stock-banner__text">{{ selectedSalesAttr || language_v2?.SHEIN_KEY_PWA_35553 || 'This item is' }}</span>
      <span class="low-stock-banner__tips">
        {{ will_sold_out_tips_info.tip }}
      </span>
    </p>
    <div
      ref="refAddBtn"
      class="low-stock-banner__button-area"
      @click="handleClickButtonArea"
    >
      <div class="low-stock-banner__button">
        <span>{{ language_v2?.SHEIN_KEY_PWA_29463 || 'Add Now' }}</span>
      </div>
    </div>
    <div
      class="low-stock-banner__close"
      @click="handleClickClose"
    >
      <Icon
        name="sui_icon_nav_close_24px"
        color="#C1C1C1"
        width="14px"
        height="14px"
      />
    </div>
  </div>
</template>

<script>
import { expose } from 'public/src/pages/common/analysis/directive'
import { defineComponent, ref, watch, computed, onMounted, onUnmounted } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { getLocalStorage } from '@shein/common-function'
import {
  useMapStateV2,
  useMapGetters,
  useMapActions,
} from 'public/src/pages/goods_detail_v2/utils/useVuex.js'
import { isLogin } from 'public/src/pages/common/utils/index.js'


export default defineComponent({
  name: 'LowStockBanner',
  directives: {
    expose,
  },
  components: {
    Icon,
  },
  setup() {
    const refAddBtn = ref(null)
    const isShow = ref(false)
    const userClickClose = ref(false)
    const userAddToCart = ref(false)
    const { sku_info, cur_main_sale_attribute, will_sold_out_tips_info, is_skc_low_stock, base_info, language_v2, fs_abt } =
      useMapGetters('productDetail/common', [
        'is_skc_low_stock',
        'will_sold_out_tips_info',
        'sku_info',
        'base_info',
        'language_v2',
        'fs_abt',
        'cur_main_sale_attribute'
      ])
    const { externalSizeInfoIndex } = useMapStateV2('newProductDetail/common', [
      'externalSizeInfoIndex',
    ])
    const { sizeUtils } = useMapStateV2('newProductDetail/SizeBox', [
      'sizeUtils',
    ])
    const { is_show_colors } = useMapGetters('productDetail/MainSaleAttr', ['is_show_colors'])
    const { brand_deals_info } = useMapGetters('productDetail/promotion', ['brand_deals_info'])
     
    // buy now 逻辑先注释
    // const { buy_now_info } = useMapGetters('productDetail/addBag', [
    //   'buy_now_info',
    // ])
    // const { click_buy_now_btn } = useMapActions('productDetail/addBag', [
    //   'click_buy_now_btn',
    // ])

    const { add_to_bag } = useMapActions('productDetail/addBag', ['add_to_bag'])

    const selectedSalesAttr = computed(() => {
      const attrList = []
      // 是否展示主销售属性
      if(is_show_colors.value && cur_main_sale_attribute.value?.attr_value) {
        attrList.push(cur_main_sale_attribute.value.attr_value)
      }
      if(externalSizeInfoIndex.value) {
        Object.values(externalSizeInfoIndex.value)?.forEach(item => {
          if(item.attr_value_id){
            const text = sizeUtils.value?.handleCalcSize?.(item)?.renderText
            attrList.push(text || item.attr_value_name)
          }
        })
      }
      return attrList.length ? `“${attrList.join(',')}”` : ''
    })

    const visible = computed(() => {
      // 满足低库存条件且用户未关闭且未加入购物车
      return isShow.value && !userClickClose.value && !userAddToCart.value
    })

    function handleAddToCart() {
      add_to_bag({
        reportPostData: {
          location: 'bottomBanner',
        },
        isFromBottomBanner: true,
        target: refAddBtn.value,
      })
    }

    // function handleBuyNow() {
    //   click_buy_now_btn()
    // }

    function handleClickButtonArea() {
      handleAddToCart()
    }

    watch(
      () => base_info?.value?.product_relation_id,
      () => {
        // 切换商品时重置状态（切换skc不重置）
        userClickClose.value = false
      },
    )

    watch(
      () => base_info?.value?.goods_id,
      () => {
        // 切换skc重置状态
        userAddToCart.value = false
      },
    )

    watch(
      () => will_sold_out_tips_info,
      () => {
        // 规避注册弹窗
        if (
          is_skc_low_stock.value &&
          will_sold_out_tips_info?.value?.tip && 
          (isLogin() || getLocalStorage('hide_download_bar') === 1) && 
          !brand_deals_info.value
        ) {
          isShow.value = fs_abt?.value?.Detailsalmostsoldout?.param?.bottombanner_lowstock === 'on'
        } else {
          isShow.value = false
        }
      },
      {
        deep: true,
        immediate: true,
      },
    )

    watch(() => visible.value, (val) => {
      if (val) {
        // 用于规避回到顶部按钮
        document.documentElement.style.setProperty('--low-stock-banner-bottom-gap', '50px')
      } else {
        document.documentElement.style.setProperty('--low-stock-banner-bottom-gap', '0px')
      }
    }, { immediate: true })

    onMounted(() => {
      window.vBus && window.vBus.on('closeLowStockBanner', () => {
        userAddToCart.value = true
      })
    })

    onUnmounted(() => {
      window.vBus && window.vBus.off('closeLowStockBanner')
    })

    function handleClickClose() {
      userClickClose.value = true
    }

    return {
      userClickClose,
      visible,
      sku_info,
      will_sold_out_tips_info,
      handleClickClose,
      handleClickButtonArea,
      language_v2,
      selectedSalesAttr,
      refAddBtn
    }
  },
})
</script>

<style lang="less">
.low-stock-banner {
  display: flex;
  align-items: center;
  width: calc(100vw - 0.64rem);
  padding-left: 0.21rem;
  margin: 0 0.32rem;
  border-radius: 0.05333rem;
  position: absolute;
  left: 0;
  bottom: calc(100% + 0.21rem);
  background: black;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color: #fff;
  font-family: 'SF Pro';
  font-size: 0.32rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &__icon {
    width: 1.06667rem;
    height: 1.06667rem;
    margin-right: 0.21rem;
  }

  &__content {
    padding-right: 0.214rem;
    box-sizing: border-box;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #ff734d;
  }

  &__text {
    display: inline-block;         
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: text-bottom;
    color: #fff;
  }

  &__tips {
    display: inline;
    font-weight: 590;
    text-overflow: ellipsis; 
  }

  &__button-area {
    margin-left: auto;
    max-width: 30%;
    padding-right: 0.21rem;
    box-sizing: border-box;
    overflow: hidden;
    flex-shrink: 0;
  }

  &__button {
    display: flex;
    padding: 0.10667rem 0.21333rem;
    justify-content: center;
    align-items: center;
    border-radius: 2.66667rem;
    border: 0.5px solid #fff;
    color: #fff;
    font-family: 'SF Pro';
    font-size: 0.29333rem;
    font-style: normal;
    font-weight: 510;
    line-height: normal;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__close {
    width: 0.58667rem;
    height: 0.58667rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    flex-shrink: 0;
  }
}
</style>
