<template>
  <div class="productPriceTips">
    <LowestPriceTips
      v-if="lowest_price_tips"
      :lowest-price-tips="lowest_price_tips"
    />
    <AfterPayInfo
      v-if="after_pay_info"
      :after-pay-info="after_pay_info"
      :show-list="showList"
      @toogleList="toogleList"
    />
    <AppExclusiveDiscount
      v-if="app_exclusive_discount"
      :app-exclusive-discount="app_exclusive_discount"
    />
  </div>
</template>

<script setup name="PriceTips">
/**
 * @file 主价格和促销楼层之间的价格提示信息
*/
import { ref, computed, defineAsyncComponent } from 'vue'
import { useMapGetters } from 'public/src/pages/goods_detail_v2/utils/useVuex.js'

const AfterPayInfo = defineAsyncComponent(() => import('./components/AfterPayInfo.vue'))
const AppExclusiveDiscount = defineAsyncComponent(() => import('./components/AppExclusiveDiscount.vue'))
const LowestPriceTips = defineAsyncComponent(() => import('./components/LowestPriceTips.vue'))

const {
  lowest_price_tips,
  after_pay_info,
  app_exclusive_discount,
} = useMapGetters('productDetail/priceTips', [
  'lowest_price_tips',
  'after_pay_info',
  'app_exclusive_discount',
])

const showList = ref(false)

const toogleList = () => {
  showList.value = !showList.value
}

</script>

<style lang="less">
.productPriceTips {}
</style>