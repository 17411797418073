<template>
  <div 
    v-if="buyNowInfo.show"
    v-expose="expose"
    class="productBuyNowBtn"
    @click="handleClick"
  >
    <s-loading v-show="loading" />
    <s-adapter-text
      v-show="!loading"
      min-size="12"
      :text="text"
    />
  </div>
</template>

<script setup name="BuyNowBtn">
import { SAdapterText } from '@shein-aidc/sui-adapter-text/mobile'
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import { vExpose } from 'public/src/pages/common/analysis/directive'
import { ref, computed, onMounted } from 'vue'
import { useMapActions } from 'public/src/pages/goods_detail_v2/utils/useVuex.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'

const { click_buy_now_btn } = useMapActions('productDetail/addBag', ['click_buy_now_btn'])

const props = defineProps({
  buyNowInfo: {
    type: Object,
    default: () => ({})
  },
  isReview: {
    type: Boolean,
    default: false
  },
})

const loading = ref(false)
const text = computed(() => props.buyNowInfo.text)

const analyzeData = computed(() => props.buyNowInfo.analyzeData)
const expose = computed(() => analyzeData.value?.expose || {})

onMounted(() => {
  if (!props.buyNowInfo.show && analyzeData.value?.exposeEligShow) {
    daEventCenter.triggerNotice(analyzeData.value?.exposeEligShow)
  }
})

const handleClick = async () => {
  loading.value = true
  await click_buy_now_btn()
  loading.value = false
}

</script>

<style lang="less">
.productBuyNowBtn {
  height: 1.0667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
  background: #FFF;
  color: #000;
  font-size: 16px;
  font-weight: 700;
  padding: 0 0.4rem;
  box-sizing: border-box;
  .sui-loading__common-inner {
    margin: 0 !important;
  }
  .sui-adapter-text {
    width: 100%;
    text-align: center;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
  }
}
</style>
